import {Routes, RunGuardsAndResolvers} from '@angular/router';
import {PackageDetailsResolver, resolvePackageData} from './pages/package/packageDetailsData';
import {HomeResolver} from './pages/home/homeData';
import {TagsResolver} from './pages/tags/tags-resolver';
import {ReleasesListResolver} from './pages/releases/releases-list-data';
import {CompanyDetailsResolver} from './pages/company/company-details-data';
import {UserDataResolver} from './pages/account/userData';
import {DefaultResolver} from './default-resolver';
import {AuthService, guardAuthIsLogged} from './services/authService';
import {LandingDataResolver} from './pages/landing/landingData';
import {ContestListData} from './pages/contest/contestListData';
import {resolveContestDetailsData} from './pages/contest/contestDetailsData';
import {resolveMuteData} from './pages/mute/muteData';
import {resolveUnsubscribeData} from './pages/unsubscribe/unsubscribeData';
import {ADMIN_PANEL_ROUTE} from './app.routes.admin';
import {oexSidebarItems} from './services/sidebars/oex-portal-sidebar';
import {companyEditorCanDeactivateResolver} from './pages/company/my-company';
import {packageEditorCanDeactivateResolver} from './pages/package/package-editor/package-editor.component';

let PORTAL_CHILDREN: Routes;
PORTAL_CHILDREN = [
  {
    path: '',
    loadComponent: () => import('./portal/home/portal-home.screen').then(m => m.PortalHomeScreenComponent)
  },
  {
    path: 'profile',
    children: [
      {
        path: 'personal',
        loadComponent: () => import('./pages/account/profile-editor').then(m => m.ProfileEditorScreenComponent)
      },
      {
        path: 'subscriptions',
        loadComponent: () => import('./pages/account/subscriptions').then(m => m.SubscriptionsScreenComponent)
      }
      /*{ path: 'reviews', component: MyReviewsScreenComponent },
      { path: 'ecosystem-services', component: PromotionComponent }*/
    ]
  },
  {
    path: 'products',
    title: 'Applications',
    children: [
      {
        path: '',
        loadComponent: () => import('./portal/my-products/my-products').then(m => m.MyProductsScreenComponent)
      },
      {
        loadComponent: () => import('./pages/package/package-editor/package-editor.component').then(m => m.PackageEditorComponent),
        canDeactivate: [packageEditorCanDeactivateResolver],
        title: 'EditProduct',
        path: ':id/edit',
        resolve: {model: PackageDetailsResolver}
      },
      {
        path: ':id/analytics',
        title: 'PortalPackageAnalytics',
        loadComponent: () => import('./pages/package-downloads/packageDownloads').then(m => m.PackageDownloadsComponent),
        data: {
          ignoreChildNWS: true
        },
        resolve: {model: PackageDetailsResolver}
      },
      {
        loadComponent: () => import('./pages/versionHistory/versionHistory').then(m => m.VersionHistoryComponent),
        data: {
          ignoreChildNWS: true
        },
        title: 'PortalPackageReleases',
        path: ':id/releases',
        resolve: {model: PackageDetailsResolver}
      },
      {
        loadComponent: () => import('./pages/reviews/reviews-list.component').then(m => m.ReviewsListComponent),
        title: 'PortalPackageReviews',
        path: ':id/reviews',
        resolve: {model: PackageDetailsResolver}
      }
    ]
  },
  {
    path: 'statistics',
    title: 'Statistics',
    loadComponent: () => import('./pages/account/my-apps/my-apps').then(m => m.MyAppsScreenComponent)
  },
  {
    path: 'messages',
    title: 'Messages',
    loadComponent: () => import('./portal/messages/portal-messages.component').then(m => m.PortalMessagesComponent)
  },
  {
    path: 'reviews',
    title: 'Reviews',
    loadComponent: () => import('./pages/account/my-reviews').then(m => m.MyReviewsScreenComponent)
  },
  {
    path: 'app-quality',
    title: 'App quality',
    loadComponent: () => import('./admin/app-quality/admin-app-quality').then(m => m.AdminAppQualityComponent)
  },
  {
    path: 'certification',
    title: 'Certification',
    loadComponent: () => import('./portal/certs/portal-certs.screen').then(m => m.PortalCertsScreenComponent)
  },
  {
    path: 'benefits/intersystems',
    title: 'Partner Benefits - Software',
    loadComponent: () => import('./portal/benefits-is/benefits-is.screen').then(m => m.PortalBenefitsISScreenComponent)
  },
  {
    path: 'contacts',
    title: 'Contacts',
    loadComponent: () => import('./portal/contacts/portal-contacts.screen').then(m => m.PortalContactsScreenComponent)
  },
  {
    path: 'bookmarks',
    title: 'Bookmarks',
    loadComponent: () => import('./portal/my-bookmarks/my-bookmarks.screen').then(m => m.MyBookmarksScreenComponent)
  },
  {
    path: 'statistics',
    title: 'Statistics',
    loadComponent: () => import('./pages/account/my-apps/my-apps').then(m => m.MyAppsScreenComponent)
  },
  {
    path: 'contest',
    title: 'Contest',
    loadComponent: () => import('./portal/contest/portal-contest.screen').then(m => m.PortalContestScreenComponent)
  }
];

/**
 * Router setup
 */
export const routes: Routes = [
  {
    path: '',
    title: 'Home',
    loadComponent: () => import('./pages/home/home').then(m => m.HomeScreenComponent),
    resolve: {model: HomeResolver},
    // canActivate: [HomeGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'apps-test',
    title: 'SolutionsTest',
    loadComponent: () => import('./pages/home/home').then(m => m.HomeScreenComponent),
    resolve: {model: HomeResolver},
    // canActivate: [HomeGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'tags',
    title: 'Tags',
    loadComponent: () => import('./pages/tags/tags-screen.component').then(m => m.TagsScreenComponent),
    resolve: {model: TagsResolver},
    runGuardsAndResolvers: 'always'
  },
  // Temporary redirect, requested by (#1353)
  {
    path: 'package/InterSystems-Package-Manager',
    redirectTo: 'package/InterSystems-Package-Manager-1'
  },
  {
    path: 'package/:id',
    title: 'Package',
    loadComponent: () => import('./pages/package/packageDetails').then(m => m.PackageDetailsScreenComponent),
    resolve: {model: resolvePackageData}
  },
  {
    path: 'releases',
    title: 'Releases',
    loadComponent: () => import('./pages/releases/releases-list').then(m => m.ReleasesListComponent),
    resolve: {model: ReleasesListResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'about-contests',
    title: 'Contests',
    loadComponent: () => import('./pages/contest-landing/contest-landing.component').then(m => m.ContestLandingComponent)
  },
  {
    path: 'members',
    title: 'Members',
    loadComponent: () => import('./pages/members/members').then(m => m.MembersScreenComponent)
  },
  {
    path: 'company/:company',
    title: 'Company',
    loadComponent: () => import('./pages/company/company-details').then(m => m.CompanyDetailsComponent),
    runGuardsAndResolvers: 'paramsChange' as RunGuardsAndResolvers
  },
  {
    path: 'search/:term',
    title: 'Search',
    loadComponent: () => import('./pages/home/home').then(m => m.HomeScreenComponent),
    resolve: {model: HomeResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'user/:name/:key',
    title: 'UserInfo',
    loadComponent: () => import('./pages/account/user').then(m => m.UserScreenComponent),
    resolve: {model: UserDataResolver}
  },
 /* {
    path: 'profile/:name/:key',
    title: 'UserProfile',
    loadComponent: () => import('./pages/account/profile').then(m => m.ProfileScreenComponent),
    resolve: {model: DefaultResolver},
    canActivate: [AuthService]
  },
  {
    path: 'profile/edit',
    title: 'ProfileEditor',
    loadComponent: () => import('./pages/account/profile-editor').then(m => m.ProfileEditorScreenComponent),
    resolve: {model: DefaultResolver},
    canActivate: [AuthService]
  },*/
  {
    path: 'news',
    title: 'News',
    loadComponent: () => import('./pages/news/news').then(m => m.NewsScreenComponent),
    resolve: {model: DefaultResolver}
  },
  // {path: 'news/page/:num', title: 'News', component: NewsScreenComponent, resolve: {model: DefaultResolver}},
  /* {
     path: 'top',
     title: 'TopDownloaded',
     loadComponent: () => import('./home/home').then(m => m.HomeScreenComponent),
     resolve: {model: HomeResolver},
     runGuardsAndResolvers: 'always'
   },
   {
     path: 'topRated',
     title: 'TopRated',
     loadComponent: () => import('./home/home').then(m => m.HomeScreenComponent),
     resolve: {model: HomeResolver},
     runGuardsAndResolvers: 'always'
   },*/
  {
    path: 'markdown',
    title: 'Markdown',
    loadComponent: () => import('./pages/markdown/markdown').then(m => m.MarkdownScreenComponent)
  },
  {
    path: 'about',
    title: 'About',
    loadComponent: () => import('./pages/landing/about').then(m => m.AboutComponent),
    resolve: {model: LandingDataResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'contests',
    title: 'Contests',
    loadComponent: () => import('./pages/contest/contestList').then(m => m.ContestListComponent),
    resolve: {model: ContestListData},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'contest/:id',
    title: 'Contest',
    loadComponent: () => import('./pages/contest/contestDetails').then(m => m.ContestDetailsComponent),
    resolve: {model: resolveContestDetailsData},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'contest/:id/participants',
    title: 'ContestParticipants',
    loadComponent: () => import('./pages/contest/participants/contestParticipants').then(m => m.ContestParticipantsScreenComponent),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'contest/edit/:id',
    title: 'ContestCreate',
    loadComponent: () => import('./pages/contest/contestEdit').then(m => m.ContestEditComponent),
    resolve: {model: DefaultResolver},
    canActivate: [AuthService]
  },
  {
    path: 'sub/suspend/:key',
    title: 'Unsubscribe',
    loadComponent: () => import('./pages/unsubscribe/unsubscribe').then(m => m.UnsubscribeScreenComponent),
    resolve: {model: resolveUnsubscribeData},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'sub/mute/:key/:sid',
    title: 'Mute',
    loadComponent: () => import('./pages/mute/mute').then(m => m.MuteScreenComponent),
    resolve: {model: resolveMuteData},
    runGuardsAndResolvers: 'always'
  },
  /* {
     path: 'view-company/:viewCompanyPortalNws/portal',
     loadComponent: () => import('./../oex-ui-kit/components/portal-page/portal-page.component').then(m => m.PortalPageComponent),
     resolve: {
       sidebar: oexSidebarItems
     },
     runGuardsAndResolvers: 'always',
     canActivate: [guardAuthIsLogged],
     children: PORTAL_CHILDREN
   },*/
  {
    path: 'portal',
    title: 'Portal',
    loadComponent: () => import('./../oex-ui-kit/components/portal-page/portal-page.component').then(m => m.PortalPageComponent),
    resolve: {
      sidebar: oexSidebarItems
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [guardAuthIsLogged],
    children: PORTAL_CHILDREN
  },
  {
    path: 'preview', children: [
      {
        path: 'company/:company',
        loadComponent: () => import('./pages/preview/ssr-card-preview').then(m => m.SSRCardPreviewComponent),
        resolve: {model: CompanyDetailsResolver},
        runGuardsAndResolvers: 'paramsChange' as RunGuardsAndResolvers
      },
      {
        path: 'package/:id',
        loadComponent: () => import('./pages/preview/ssr-card-preview').then(m => m.SSRCardPreviewComponent),
        resolve: {model: PackageDetailsResolver},
        runGuardsAndResolvers: 'paramsChange' as RunGuardsAndResolvers
      },
      {
        path: 'member/:name/:key',
        loadComponent: () => import('./pages/preview/ssr-card-preview').then(m => m.SSRCardPreviewComponent),
        resolve: {model: UserDataResolver},
        runGuardsAndResolvers: 'paramsChange' as RunGuardsAndResolvers
      }
    ]
  },
  {
    path: 'map',
    loadComponent: () => import('./pages/company/companies-map/companies-map').then(m => m.CompaniesMapComponent)
  },
  ADMIN_PANEL_ROUTE,
  {
    path: '**',
    loadComponent: () => import('./pages/not-found-page/not-found-page.component').then(m => m.NotFoundScreenComponent)
  }
];
