import {DefaultResolver} from '../../default-resolver';
import {Meta} from '@angular/platform-browser';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpService} from '../../services/httpService';
import {Observable} from 'rxjs/internal/Observable';
import {ConfigService} from '../../services/configService';

import {ICompany} from '../../services/api.types';

export interface ILandingData {
  apps: any[];
  companies: ICompany[];
}

@Injectable({
  providedIn: 'root'
})
export class LandingDataResolver implements Resolve<any> {
  private model: ILandingData = {
    apps: [],
    companies: []
  };

  constructor(private http: HttpService,
              private meta: Meta) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return new Observable<any>(o => {
      DefaultResolver.resetMetaTags(this.meta);

      const done = () => {
        o.next(this.model);
        o.complete();
      };

      // Request data
      Promise.all([this.requestApps()])
        .finally(() => {
          done();
        });
    });
  }

  requestApps() {
    return this.http.request(`/mpapi/packages/find_pagination?term=&cats=&ww=&ind=&sorting=dw.desc&pageSize=50`)
      .then(d => {
        this.model.apps = (d.items ?? []).filter(a => a.ImageURL).slice(0, 15);
      })
      .catch(e => {
      });
  }
}
