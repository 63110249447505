import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {HttpService} from '../../services/httpService';

export interface IUnsubscribeDataModel {
  isError: boolean;
  errorMessage: string;
}

export const resolveUnsubscribeData: ResolveFn<IUnsubscribeDataModel> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const http = inject(HttpService);
  const key = route.paramMap.get('key');
  const model: IUnsubscribeDataModel = {
    isError: false,
    errorMessage: ''
  };
  try {
    return await http.request(`/mpapi/sub/suspend/${key}`);
  } catch (e) {
    model.isError = true;
    model.errorMessage = http.getErrorText(e);
  }
  return model;
}
