<div>
  @for (b of items; track b) {
    @if (b.link) {
      <a class="mp-link secondary" [routerLink]="(st.isMarketplace ? '/' + mpRoute + '/' : '') + b.link" [class.no-link]="!b.link && !b.isAction" (click)="onItemClick(b)">
        @if (b.icon) {
          <img [src]="b.icon">
        }
        {{b.label}}
      </a>
    } @else {
      <a class="mp-link secondary" (click)="onItemClick(b)">
        @if (b.icon) {
          <img [src]="b.icon">
        }
        {{b.label}}
      </a>
    }
  }
</div>
