import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {HttpService} from '../../services/httpService';

export interface IMuteDataModel {
  isError: boolean;
  errorMessage: string;
  name: string;
  link: string;
}

export const resolveMuteData: ResolveFn<IMuteDataModel> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const http = inject(HttpService);
  const key = route.paramMap.get('key');
  const sid = route.paramMap.get('sid');
  const model: IMuteDataModel = {
    isError: false,
    errorMessage: '',
    name: '',
    link: ''
  };
  try {
    let d = await http.request(`/mpapi/sub/mute/${key}/${sid}`);
    model.name = d.name;
    model.link = d.link;
  } catch (e) {
    model.isError = true;
    model.errorMessage = http.getErrorText(e);
  }

  return model;
};
