import {ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot} from '@angular/router';
import {HttpService} from '../../services/httpService';
import {Meta} from '@angular/platform-browser';
import {ConfigService} from '../../services/configService';
import {Inject, inject, Injectable, LOCALE_ID} from '@angular/core';
import {DatePipe, Location} from '@angular/common';
import {ContestBonus} from './contestEdit';
import {Observable} from 'rxjs/internal/Observable';
import {OEXMarkdown} from '../../services/markdown';
import {AuthService} from '../../services/authService';
import {PartnersDirectoryService} from '../../services/partnersDirectory';
import {AppService} from '../../services/appService';
import {APIService} from '../../services/api.service';

export interface IContestParticipant {
  id: string;
  name: string;
  link: string;
  authorLink: string;
  image: string;
  author: string;
  description: string;
  date: string;
  rating: number;
  viewersRating: string;
  vote: number;
  bonuses: string[];
  place: number;
  status: string;
  newcomer: number;
  // for UI
  isPlacesOpened: boolean;
}

export interface IContestStatParticipant {
  name: string;
  nameWithoutSpaces: string;
}

export interface IContestStatUser {
  firstName: string;
  individualKey: string;
  avatar: string;
}

export interface IContestStatApp {
  count: number;
  participants: IContestStatParticipant[];
}

export interface IContestStatMember {
  count: number;
  members: IContestStatUser[];
}

export interface IContestStats {
  appsTotal: IContestStatApp;
  appsNew: IContestStatApp;
  uniqueDev: IContestStatMember;
  newcomers: IContestStatMember;
  firstApp: IContestStatMember;
}

export interface IContestReward {
  id: string;
  nomination: string;
  participantId: string;
  place: number;
}

export class ContestDetailsModel {
  sorting;
  id = '';
  name = '';
  regStart = '';
  regEnd = '';
  voteStart = '';
  voteEnd = '';
  description = '';
  descriptionMD = '';
  published = 0
  archived = 0
  strictJudges = 0
  participants: IContestParticipant[] = [];
  bonuses: ContestBonus[] = [];
  stats?: IContestStats;
  rewards?: IContestReward[];
  // Runtime generated
  dateTitle = '';
  dateValue = '';
  // Formatted date values
  regStartFmt = '';
  regEndFmt = '';
  voteStartFmt = '';
  voteEndFmt = '';
  searchTerm = '';
  searchBonuses = '';
  announcementURL = '';
}

export const CONTEST_DATE_FORMAT = 'dd MMM, yyyy';
export const CONTEST_DATETIME_FORMAT = 'dd MMM, yyyy, h:mm:ss a';

export const resolveContestDetailsData: ResolveFn<ContestDetailsModel> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(ContestDetailsData).resolve(route, state);
};

@Injectable({
  providedIn: 'root'
})
export class ContestDetailsData {
  static isFirstRun = true;
  private model!: ContestDetailsModel;
  private datePipe: DatePipe;
  private stats?: IContestStats;

  constructor(private http: HttpService,
              private api: APIService,
              private conf: ConfigService,
              private auth: AuthService,
              private meta: Meta,
              private router: Router,
              private pd: PartnersDirectoryService,
              private location: Location,
              @Inject(LOCALE_ID) private locale: string) {
    this.datePipe = new DatePipe(locale);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return new Observable<any>(o => {
      const done = () => {
        o.next(this.model);
        o.complete();
      };

      if (route.params.id === 'current') {
        const id = ConfigService.contest?.id;
        if (id) {
          void this.router.navigateByUrl(`/contest/${id}`);
        } else {
          void this.router.navigateByUrl(`/about-contests`);
        }
        o.complete();
        return;
      }

      const reqDates = this.conf.requestContest();
      const reqStats = this.requestStatistics(route.params.id);

      // Request data
      const reqData = this.api.getContest(route.params.id, route.queryParams.term, route.queryParams.bonuses)
        .then(d => {
          this.model = d;
          this.applySorting(route);
          this.parseMarkdown();
          this.prepareBonuses();
          this.applyFiltering(route);
        })
        .catch(() => {
          void this.router.navigateByUrl('/about-contests');
          o.complete();
        });

      Promise.all([reqData, reqDates, reqStats]).then(() => {
        if (this.stats) {
          this.model.stats = this.stats;
        }
        this.buildDateText();
        this.updateMetaTags();
        done();
      });
    });
  }

  /**
   * Updates meta tags with app info
   */
  updateMetaTags() {
    const m = this.model;
    const img = this.pd.enabled ? 'https://partner.intersystems.com/assets/img/logo.svg' : 'https://openexchange.intersystems.com/assets/img/logo.svg';
    this.meta.addTag({name: 'og:title', property: 'og:title', content: m?.name || ''});
    this.meta.addTag({name: 'og:description', property: 'og:description', content: m?.descriptionMD || ''});
    this.meta.addTag({name: 'og:image', property: 'og:image', content: img});
    this.meta.addTag({name: 'og:image:secure_url', property: 'og:image:secure_url', content: img});
  }

  /**
   * Apply sorting for participants list
   * @param route
   */
  applySorting(route: ActivatedRouteSnapshot) {
    //let sorting = route.queryParams.sort || (ConfigService.canVote() || ConfigService.isContestEnded() ? 'experts' : 'date');
    /*if (!this.auth.isAdmin && this.model?.archived !== 1 && sorting === 'experts') {
      sorting = 'date';
    }*/
    this.model.sorting = route.queryParams.sort || (this.auth.isAdmin ? 'experts' : 'date');
    if (!route.queryParams.sort && this.model.archived === 1) {
      this.model.sorting = 'experts';
    }

    if (!this.model || !this.model.participants) {
      return;
    }

    switch (this.model.sorting) {
      case 'name':
        this.model.participants.sort((p1, p2) => {
          return p1.name.toLocaleLowerCase().localeCompare(p2.name.toLocaleLowerCase());
        });
        break;
      case 'date':
        this.model.participants.sort((p1, p2) => {
          return (new Date(ConfigService.fromServerDate(p1.date)) < new Date(ConfigService.fromServerDate(p2.date))) ? -1 : 1;
        });
        break;
      case 'experts':
        this.model.participants.sort((p1, p2) => {
          if (p1.rating === p2.rating) {
            if (p1.viewersRating === p2.viewersRating) {
              return (new Date(ConfigService.fromServerDate(p1.date)) < new Date(ConfigService.fromServerDate(p2.date))) ? -1 : 1;
            }
            return p1.viewersRating > p2.viewersRating ? -1 : 1;
          }
          return p1.rating > p2.rating ? -1 : 1;
        });
        break;
      case 'community':
        this.model.participants.sort((p1, p2) => {
          if (p1.viewersRating === p2.viewersRating) {
            if (p1.rating === p2.rating) {
              return (new Date(ConfigService.fromServerDate(p1.date)) > new Date(ConfigService.fromServerDate(p2.date))) ? -1 : 1;
            }
            return p1.rating > p2.rating ? -1 : 1;
          }
          return p1.viewersRating > p2.viewersRating ? -1 : 1;
        });
        break;
    }
  }

  /**
   * Parses markdown
   */
  private parseMarkdown() {
    this.model.descriptionMD = '';
    const desc = this.model.description;
    if (!desc) {
      return;
    }
    this.model.descriptionMD = OEXMarkdown.parse(desc);
  }

  /**
   * Is current period before contest registration date
   */
  private isBeforeRegistration(): boolean {
    return !ConfigService.isContestEnded() && !ConfigService.isContestActive() && !ConfigService.canVote();
  }

  /**
   * Is current period inside registration period
   */
  private isRegistrationStarted(): boolean {
    return !ConfigService.isContestEnded() && ConfigService.isContestActive() && !ConfigService.canVote();
  }

  /**
   * Is regustration ended
   */
  private isRegistrationEnded(): boolean {
    return !ConfigService.isContestEnded() && !ConfigService.isContestActive() && ConfigService.canVote();
  }


  /**
   * Builds date text
   */
  private buildDateText() {
    this.model.dateTitle = '';
    this.model.dateValue = '';
    this.formatDates();
    if (this.isBeforeRegistration()) {
      this.model.dateTitle = 'Registration Starts';
      this.model.dateValue = this.model.regStartFmt;
    }

    if (this.isRegistrationStarted()) {
      this.model.dateTitle = 'Registration Ends';
      this.model.dateValue = this.model.regEndFmt;
    }

    if (this.isRegistrationEnded()) {
      this.model.dateTitle = 'Voting Ends';
      this.model.dateValue = this.model.voteEndFmt;
    }

    if (!this.model.dateTitle) {
      this.model.dateTitle = '';
      this.model.dateValue = this.model.regStartFmt + ' - ' + this.model.voteEndFmt;
    }
  }

  /**
   * Formats dates
   * @param date
   */
  private formatDates() {
    if (this.model.regStart) {
      this.model.regStartFmt = this.convertDateTime(this.model.regStart) ?? '';
    }
    if (this.model.regEnd) {
      this.model.regEndFmt = this.convertDateTime(this.model.regEnd) ?? '';
    }
    if (this.model.voteStart) {
      this.model.voteStartFmt = this.convertDateTime(this.model.voteStart) ?? '';
    }
    if (this.model.voteEnd) {
      this.model.voteEndFmt = this.convertDateTime(this.model.voteEnd) ?? '';
    }
  }

  /**
   * Converts date to format "20 March, 2020, 19:20:23"
   * @param date
   */
  private convertDate(date: string) {
    const d = new Date(ConfigService.fromServerDate(date));
    return this.datePipe.transform(d, CONTEST_DATE_FORMAT);
  }

  /**
   * Converts date to format "20 March, 2020, 19:20:23"
   * @param date
   */
  private convertDateTime(date: string) {
    const d = new Date(ConfigService.fromServerDate(date));
    return this.datePipe.transform(d, CONTEST_DATETIME_FORMAT);
  }

  private prepareBonuses() {
    if (!this.model?.bonuses?.length) {
      return;
    }

    this.model.bonuses.forEach(b => {
      b.text = b.description;
      b.value = (b.id ?? '').toString();
    });
  }

  private applyFiltering(route: ActivatedRouteSnapshot) {
    this.model.searchTerm = route.queryParams.term || '';
    this.model.searchBonuses = AppService.decodeText(route.queryParams.bonuses || '');
    const selected = this.model.searchBonuses.split(',');
    this.model.bonuses.forEach(b => {
      b.selected = selected.includes((b.id ?? '').toString());
    });
  }

  private async requestStatistics(id: string): Promise<void | IContestStats> {
    return this.http.request(`/mpapi/contest/get/${id}/stat`)
      .then(data => {
        this.stats = data;
      });
  }
}
