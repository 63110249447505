<!--<oex-banner-gs-2022 *ngIf="!st.isPortal"></oex-banner-gs-2022>-->

<!-- Top nav bar -->
@if (!isPreview && !isMarketplace) {
  <ui-external-header
    iconClass="icon-admin-panel"
    [adminRouterLink]="auth.isAdmin ? '/admin' : ''"
    [currentSiteName]="isMarketplace ? 'Health Innovation Hub' : (pd.enabled ? 'Partner Directory' : 'Open Exchange')"
  >
  </ui-external-header>
}

<!-- Header -->
@if (!isPreview) {
  <ui-header
    #header
    [url]="isMarketplace ? ('/' + mpRoute) : '/'"
    [logo]="siteLogo"
    [items]="menuItems"
    [isLogged]="auth.isLogged"
    [showSearch]="!pd.enabled"
    [userName]="auth.user?.name ?? ''"
    [userAvatar]="auth.user?.avatar ?? ''"
    (onSearch)="onSearch($event)"
    (onRegister)="onRegisterClick()"
    (onLogin)="onLogin()"
  ></ui-header>
}

<!-- Breadcrumbs -->
<oex-breadcrumbs></oex-breadcrumbs>

<!-- Main content -->
<div class="mp-main">
  <router-outlet (activate)="scrollToTop()"></router-outlet>
</div>

<!-- Modal modals -->
<div class="mp-modal-backdrop" [hidden]="!modals.length" (mousedown)="modal.closeOnBackdropClick($event)">
  @for (modal of modals; track modal; let idx = $index) {
    <oex-modal class="mp-modal" [hidden]="idx !== modals.length - 1" [data]="modal"></oex-modal>
  }
</div>

<!-- Footer: PD -->
@if (pd.enabled && isFooter) {
  <div class="pd-footer">
    <div>
      <div class="ft-fin">
        <a href="https://www.intersystems.com/industries/business/financial-services/">
          Finance
          <!--        <p>Proven speed, scalability and reliability</p>-->
        </a>
        <a href="https://www.intersystems.com/industries/healthcare-technology/">
          Health & Life Sciences
          <!--        <p>Advanced software for healthcare, powered by interoperability</p>-->
        </a>
        <a href="https://www.intersystems.com/industries/business/">
          Business
          <!--        <p>Turning data into sound business decisions</p>-->
        </a>
        <a href="https://www.intersystems.com/industries/government/">
          Government
          <!--        <p>Helping governments serve their citizens</p>-->
        </a>
      </div>
      <div class="ft-iris-data">
        <a href="https://www.intersystems.com/products/intersystems-iris/">
          InterSystems IRIS Data Platform
          <!--        <p>Data Platform for Transactions & Analytics</p>-->
        </a>
        <a href="https://www.intersystems.com/products/intersystems-iris-for-health/">
          InterSystems IRIS for Health
          <!--        <p>Data Platform for Healthcare Solutions</p>-->
        </a>
        <a href="https://www.intersystems.com/products/healthshare/">
          HealthShare
          <!--        <p>Unified Care Record</p>-->
        </a>
        <a href="https://www.intersystems.com/products/trakcare/">
          TrakCare
          <!--        <p>Unified Healthcare Information System</p>-->
        </a>
        <a href="https://www.intersystems.com/products/cache/">
          Caché
          <!--        <p>High-Performance Database</p>-->
        </a>
        <a href="https://www.intersystems.com/products/ensemble/">
          Ensemble
          <!--        <p>Integration Engine</p>-->
        </a>
      </div>
      <div class="ft-contact">
        <a href="https://www.intersystems.com/who-we-are/contact-us/">
          Contact us
        </a>
        <a href="https://www.intersystems.com/news-events/intersystems-blogs/">
          InterSystems Blogs
        </a>
        <a href="https://www.intersystems.com/news-events/events/">
          Events
        </a>
        <a href="https://isc-partnering.ideas.aha.io/">
          Share your ideas
        </a>
        <a href="https://discord.com/channels/698987699406766170/863351145896214528" target="_blank"
           rel="noopener noreferrer">
          <img src="/assets/img/discord-button.svg">
        </a>
      </div>
      <div class="ft-partner">
        <a href="/services">
          Find a Partner
        </a>
        <a onclick="window['oexDialogService'].showNewCompanyDialog(true)">
          Become a Partner
        </a>
        <a href="/solutions">
          Find a Product
        </a>
        <a href="/technology-partners-listing">
          For technology partners
        </a>
      </div>
      <div class="ft-help">
        <a href="https://www.intersystems.com/support-learning/support/immediate-help/">
          For Immediate Help
        </a>
        <a href="https://www.intersystems.com/support-learning/learning-services/">
          Learning Services
        </a>
        <a href="https://github.com/intersystems-community/openexchange">
          Report an issue
        </a>
      </div>
    </div>
  </div>
}

<!-- Footer -->
@if (isFooter) {
  <div class="mp-footer" id="main-footer">
    <div>
      <span>© {{ currentYear }} InterSystems Corporation, Cambridge, MA. All rights reserved.</span>
      <a href="https://www.intersystems.com/privacy-policy/">Privacy & Terms</a>
      <a href="https://www.intersystems.com/guarantee/">Guarantee</a>
      <a href="https://www.intersystems.com/section-508/">Section 508</a>
      <a href="/markdown?url=/assets/doc/contest-terms.md">Contest Terms</a>
      <div (click)="showOneTrustSettings()"><a>Cookies Settings</a></div>
    </div>
  </div>
}

