import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {IPackageDataResponse} from '../../services/packageTypes';
import {ICompanyCertificate} from '../../pages/company/company-list-data';
import {APIService} from '../../services/api.service';
import {ICompany} from '../../services/api.types';

export interface IHomePDData {
  companies: ICompany[];
  products: IPackageDataResponse[];
  certificates: ICompanyCertificate[];
}

export const resolveHomePDData: ResolveFn<IHomePDData> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const api = inject(APIService);

  let res;
  res = await Promise.all([
    api.getProductsForPDHome(),
    api.getCompaniesForPDHome(),
    api.getCertificates()
  ]);
  const [products, companies, certificates] = res;

  return {
    companies: companies?.slice(0, 4),
    products: products?.items,
    certificates: sortCertificates(certificates)
  };
};

function sortCertificates(certs?: ICompanyCertificate[]) {
  if (!certs) {
    return [];
  }
  return certs.sort((el1, el2) => el1.Order > el2.Order ? 1 : -1);
}
