<div class="holder">
  <!-- Admin panel icon -->
  @if (adminRouterLink){
    <a [routerLink]="adminRouterLink" class="btn-admin">
      <div [class]="iconClass || 'ui-icon-settings'"></div>
    </a>
  }

  <!-- Items -->
  @for (i of items; track i.text) {
    @if (i.text === currentSiteName) {
      <!-- Current site -->
      <span>{{i.text}}</span>
    } @else {
      <!-- Link -->
      <a [href]="i.url">{{i.text}}</a>
    }
  }

  @if(!isSiteExist) {
    <span>{{currentSiteName}}</span>
  }
</div>
