import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Input,
  isSignal,
  ModelSignal,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {IModalButton, ModalData, ModalService} from '../../services/modal.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';


@Component({
  standalone: true,
  selector: 'oex-modal',
  templateUrl: 'modal.html',
  imports: [],
  styleUrls: ['modal.scss']
})
export class ModalComponent implements OnInit {
  @Input() data!: ModalData;
  @ViewChild('component', {static: true, read: ViewContainerRef}) modalPlace!: ViewContainerRef;

  public isLoad: Boolean = false;
  public isProgress: Boolean = false;
  messageHtml?: SafeHtml;

  constructor(private ref: ElementRef,
              private modals: ModalService,
              private loader: ComponentFactoryResolver,
              private san: DomSanitizer,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.data.message) {
      this.data.message = this.data.message.replace(/\n/g, '<br/>');
    }

    if (this.data.useHtml) {
      this.messageHtml = this.san.bypassSecurityTrustHtml(this.data.message || '');
    }

    // Add buttons if not exists
    if (!this.data.buttons) {
      this.data.buttons = [<IModalButton>{text: 'Ok', default: true, clickOnEnter: true, cancel: true, close: true}];
    }

    // Create dynamic component in modal if specified
    if (this.data.compModel) {
      let res;
      if (this.data.compModel.component) {
        const factory = this.loader.resolveComponentFactory(this.data.compModel.component);
        res = this.modalPlace.createComponent(factory);
        this.data.component = res.instance;
        (<any>res.instance)._modal = this;
      }
      this.componentConfig(res, this.data.compModel);
      this.isLoad = true;
    } else {
      setTimeout(_ => {
        this.isLoad = true;
        this.cd.detectChanges();
      });
    }
  }

  componentConfig(inst: ComponentRef<any>, model) {
    function parseStyles(el, arr) {
      if (arr) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].name) {
            // el.find(arr[i].name).css(arr[i].prop, arr[i].value);
          } else if (arr[i].className) {
            el.classList.add(arr[i].className);
          } else {
            // el.css(arr[i].prop, arr[i].value);
          }
        }
      }
    }

    if (inst?.instance) {
      // Check component as embedded, to know inside it how it shown
      inst.instance.embedded = true;
      for (const prop in model.props) {
        //if (typeof inst.instance[prop] === 'function' || inst.instance.hasOwnProperty(prop)) {
        if (isSignal(inst.instance[prop])) {
          (inst.instance[prop] as ModelSignal<any>).set(model.props[prop]);
        } else {
          inst.instance[prop] = model.props[prop];
        }
        //}
      }
    }

    // styles correction
    if (model.styles) {
      if (inst?.location) {
        // correction of dynamic loaded component
        parseStyles(inst.location.nativeElement, model.styles.dynamicComponent);
      }
      // Correction of modal component
      parseStyles(this.ref.nativeElement, model.styles.nativeParent);
    }
  }

  close() {
    this.modals.close(this.data);
  }

  /**
   * Button click handler
   * @param {object} btn Button
   */
  onButtonClick(btn: IModalButton) {
    if (btn.click) {
      btn.click(btn, this.data.component);
    }
    if (btn.close || btn.cancel) {
      this.modals.close(this.data);
    }
  }

  setErrorMessage(msg: string) {
    this.data.errorMessage = msg;
  }

  /**
   * Show progress indicator in modal window
   */
  showProgress() {
    this.isProgress = true;
  }

  /**
   * Hide progress indicator in modal window
   */
  hideProgress() {
    this.isProgress = false;
  }
}
