import {ResolveFn, Router} from '@angular/router';
import {ISidebarItem} from '../../../oex-ui-kit/components/sidebar/sidebar.component';
import {of} from 'rxjs/internal/observable/of';
import {inject} from '@angular/core';
import {AuthService} from '../authService';

export const hihSidebarItems: ResolveFn<ISidebarItem[]> = () => {
  const auth = inject(AuthService);
  const router = inject(Router);
  const items: ISidebarItem[] = [
    {label: 'Overview', url: 'overview'},
    {label: 'Your organisation profile', url: 'company'},
    {label: 'Recommended to you', url: 'recommendations'},
    {label: 'Saved Searches', url: 'searches'},
    {label: 'Favorites', url: 'favorites'},
    {label: 'My Inquiries', url: 'inquiries'},
    {label: 'My Profile', url: 'profile'},
    {label: 'Contact InterSystems', url: 'contact'},
    {
      label: 'Log out', separator: true, style: 'link', click: () => {
        auth.logout().then(() => {
          void router.navigateByUrl('/');
        })
      }
    }
  ];

  return of(items);
}
