import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {BreadcrumbService, IBreadcrumb} from '../../services/breadcrumb.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {RouterLink} from '@angular/router';
import {SiteTrackerService} from '../../services/site-tracker.service';
import {ROUTE_MARKETPLACE} from '../../services/global-types';


@Component({
  standalone: true,
  selector: 'oex-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  imports: [
    RouterLink
],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  private subBreadcrumbsChanged?: Subscription;
  items: IBreadcrumb[] = [];
  mpRoute = ROUTE_MARKETPLACE;

  constructor(private brs: BreadcrumbService,
              public st: SiteTrackerService,
              private cd: ChangeDetectorRef) {
  }

  @HostBinding('class.hidden')
  get isHidden() {
    return this.items.length === 0;
  }

  ngOnInit() {
    this.subBreadcrumbsChanged = this.brs.onBreadcrumbsChanged.subscribe(list => this.setBreadcrumbs(list));
  }

  ngOnDestroy() {
    this.subBreadcrumbsChanged?.unsubscribe();
  }

  private setBreadcrumbs(list: IBreadcrumb[]) {
    this.items = list;
    this.cd.detectChanges();
  }

  onItemClick(b: IBreadcrumb) {
    this.brs.onBreadcrumbPressed.emit(b);
  }
}
