import {AuthService} from '../../services/authService';
import {HttpService} from '../../services/httpService';
import {Injectable} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {Location} from '@angular/common';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Meta} from '@angular/platform-browser';
import {Observable} from 'rxjs/internal/Observable';
import {IPackageReward} from '../../services/packageTypes';
import {OEXMarkdown} from '../../services/markdown';
import {PartnersDirectoryService} from '../../services/partnersDirectory';

export interface IUserSolution {
  ID: string;
  Rating: number;
  ImageURL: string;
  Name: string;
  Description: string;
  NameWithoutSpaces: string;
  DownloadsCount: number;
  Published: number;
  IsFree?: number;
  Stars: number;
  Official: number;
  PublisherID: string;
  ApprovalDate: string;
}

export interface IUserData {
  id: string;
  email: string;
  phone: string;
  firstName: string;
  network: string;
  socialEmail: string;
  description: string;
  individualKey: string;
  avatar: string;
  DCProfile: string;
  follows: number;
  urlTwitter: string;
  urlGithub: string;
  urlLinkedin: string;
  gmPoints: number;
  follow: number;
  official: number;
  items: IUserSolution[];
  rewards: IPackageReward[];

  // For UI
  markdown?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserDataResolver implements Resolve<any> {
  public model!: IUserData;
  private USER_KEY: any;
  private state?: RouterStateSnapshot;
  private route?: ActivatedRouteSnapshot;
  private name = '';
  private key = '';

  constructor(private http: HttpService,
              private auth: AuthService,
              private modal: ModalService,
              private meta: Meta,
              private ngHttp: HttpClient,
              private location: Location,
              private router: Router,
              private pd: PartnersDirectoryService) {
  }


  /**
   * Route resolver
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any>}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    // this.st.isPortal = state.url.split('/')[1] === 'portal';
    this.route = route;
    this.state = state;

    this.name = route.params.name || '';
    this.key = route.params.key || '';


    const res = new Observable<any>(o => {
      // Load package data
      const p1 = this.requestData();

      Promise.all([p1]).then(() => {
        o.next(this.model);
        o.complete();
      });
    });
    return res;
  }

  /**
   * Request account data
   */
  requestData() {
    const name = encodeURIComponent(this.name);
    const key = encodeURIComponent(this.key);

    // Get user info
    return this.http.request(`/mpapi/user/get?name=${name}&key=${key}`)
      .then((u) => {
        this.model = u;
        if (this.model.description) {
          this.parseMarkdown();
        }
        this.updateMetaTags();
      });
  }

  /**
   * Parse description
   */
  parseMarkdown() {
    const text = this.model.description;
    this.model.markdown = OEXMarkdown.parse(text);
  }

  /**
   * Updates meta tags with app info
   */
  updateMetaTags() {
    if (this.model.rewards.length) {
      const contests = [...new Set(this.model.rewards.map(r => r.contest_name))].slice(0, 3).join(', ');
      this.meta.addTag({
        name: 'og:title',
        property: 'og:title',
        content: `Open Exchange awards of ${this.model?.firstName}`
      });
      this.meta.addTag({name: 'title', property: 'title', content: `Open Exchange awards of ${this.model?.firstName}`});
      this.meta.addTag({
        name: 'og:description',
        property: 'og:description',
        content: `${this.model.rewards.length} awards in the contest(s): ${contests}`
      });
      this.meta.addTag({
        name: 'description',
        property: 'description',
        content: `${this.model.rewards.length} awards in the contest(s): ${contests}`
      });
    } else {
      this.meta.addTag({
        name: 'og:title',
        property: 'og:title',
        content: `Open Exchange profile of ${this.model?.firstName}`
      });
      const solutions = this.model.items?.slice(0, 3).map(s => s.Name).join(', ');
      if (this.model.items?.length) {
        this.meta.addTag({
          name: 'og:description',
          property: 'og:description',
          content: `${this.model.items?.length} solutions: ${solutions}`
        });
      } else {
        this.meta.addTag({name: 'og:description', property: 'og:description', content: ``});
      }
    }

    this.meta.addTag({name: 'og:url', property: 'og:url', content: this.location.path()});
    let imageUrl = `/mpapi/img/page-preview/member/${this.model?.firstName}/${this.model.individualKey}` || '';
    this.meta.addTag({name: 'og:image', property: 'og:image', content: imageUrl});
    this.meta.addTag({name: 'og:image:secure_url', property: 'og:image:secure_url', content: imageUrl});
  }
}
